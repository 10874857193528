.loadingContainer {
  text-align: center;
width: 100%;
  /* margin: 20px; */
}
.loadingTitle {
  font-size: 44px;
  font-weight: 400px ;
  color: white;

}
.loadingImage{
  width: 400px;
  height: 400px;
  margin-top: 80px;
}
@media screen and (max-width: 500px) {
.loadingImage{
  width: 300px !important;
}
}