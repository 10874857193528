.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: rgb(216, 25, 255) !important;
    height: 4px;
}

.ant-steps-icon-dot::after {
    color: rgb(68, 61, 239) !important;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
    color: white !important;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: white !important;
    /* font-size: 20px; */
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: white !important;
    font-size: 17px;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
    color: white !important;
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: white !important;
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
    color: white !important;
}

.ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: white !important;
}

.stepperContainer {
    color: white;
    width: 65%;
    padding-top: 40px;
    margin: auto !important;
}

.stepContainer {
    margin-bottom: 40px;
}

@media screen and (max-width: 800px) {
    .stepperContainer {
        width: 90% !important;
    }
}

@media screen and (max-width: 700px) {
    .stepperContainer {
        width: 90% !important;
    }
    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
        color: white !important;
        font-size: 15px;
    }
}

@media screen and (max-width: 500px) {
    .stepperContainer {
        width: 100% !important;
    }
}

@media screen and (max-width: 576px) {
    .stepContainer {
        display: none !important;
      
    }.stepperContainer{
      display: flex;
      align-items: center;
      justify-content: center;
    }
}

.wrapper {
    color: white;
    width: 60%;
    padding: 30px;
    margin: auto;
}