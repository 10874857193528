.dropzone {
  margin-top: 10px;
}
.dropzoneContainer {
  width: 100%;
  border: 2px dashed #79a8e6;
  height: 100%;
  cursor: pointer;
}
.dropzoneContainerContent {
  color: white;
  font-weight: 400;
  margin-top: 14px;
}
.span {
  color: #559df9;
  margin-left: 3px;
  font-weight: bold;
}
.heading {
  padding: 10px;
}
@media screen and (max-width: 450px) {
  .dropzoneContainerContent {
    font-size: small;
  }
}
