.titleCode {
  color: white;
  text-align: center;
  font-weight: bold;
}
.activeButton {
  width: 100%;
  height: 50px;
  background-color: rgb(68, 61, 239);
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
}
.deactive-button {
  background-color: #051d56;
  width: 100%;
  height: 45px;
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
}
.inputFields {
  font-size: 30px;
  background-color: rgb(37, 53, 111);
  border: none;
  outline: none;
  text-align: center;
  color: white !important;
  border-radius: 5px 5px 0px 0px;
  margin: 20px;
  width: 50px !important;
}
.mainHeader {
  text-align: center;
}
.otpMessageContainer{
  margin-top: 20px
}
.otpContainer {
  display: flex;
  justify-content: space-around;
}
.otpQuestionText {
  background-color: transparent;
  border: none;
  outline: none;
  font-weight: bold !important;
}
.otpQuestionTextenable {
  color: rgb(216, 25, 255);
  border: none;
  outline: none;
  font-weight: bold;
  background-color: transparent;
}
.errorText {
  color: #ff4d4d;
}

@media screen and (max-width: 800px) {
  .activeButton {
    height: 45px !important;
  }
  .deactive-button {
    height: 45px !important;
  }
  .titleCode {
    font-size: 15px !important;
  }
  .inputFields {
    width: 40px !important;
    margin: 10px;
    font-size: 24px;
  }
}
@media screen and (max-width: 500px) {
  .titleCode {
    font-size: 13px !important;
  }
}
