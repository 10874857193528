/* .header {
    padding: 30px;
    height: 100%;
    background-color: rgb(3, 14, 50) !important;
  }
   */
.successScreenContainer {
  color: white;
  text-align: center;
  margin: 20px;
}
.succesTitle {
  font-size: 54px;
  margin: 20px;
  font-weight: 100 !important;
  color: #d818ff;
}
