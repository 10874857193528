.homeScreenSuccess {
  color: white;
  text-align: center;
  margin-top: 140px;
}
.succesTitle {
  font-size: 54px;
  margin: 20px;
  font-weight: 100 !important;
  color: #d818ff;
}
.setUpAccountBtn {
  color: #d819ff;
  background-color: transparent;
  border: none;
}
.carl-logo {
  width: 200px;
  height: 80px;
  margin-right: 20px;
}
.OverViewLogo {
  width: 400px;
  margin-top: 40px;
  /* height: 140px; */
}
.HomePageContainer {
  padding: 30px;
  height: 100%;
  background-color: rgb(3, 14, 50);
}
.carlLogoContainer {
  margin-top: 80px;
  text-align: center;
}

.porfolioHeading {
  color: rgb(102, 112, 146);
  font-weight: bold;
  font-size: 26px;
}
.extraText {
  color: white;
}
.chartMainContainer {
  padding: 5px;
  margin: 5px;
  height: 90%;
}
@media screen and (max-width: 550px) {
  .OverViewLogo {
    width: 300px;
    height: 120px;
  }
  .porfolioHeading {
    font-size: 15px;
  }
  .extraText {
    font-size: 12px;
  }
  .setUpAccountBtn span {
    font-size: 14px;
  }
}
@media screen and (max-width: 450px) {
  .OverViewLogo {
    width: 100%;
    height: 120px;
  }
}
