.title {
  text-align: center;
  color: white;
  font-weight: 100;
}
.body {
  height: 400%;
}
.buttonTitle {
  color: white !important;
}
.radioButtonContainer{
  margin-top: 20px;
}
.radioButtonTitle{
  text-align: center;
}
@media screen and (max-width: 600px) {
  .buttonTitle {
    font-size: 18px !important;
  }
}
