.Input {
  height: 100%;
  width: 100%;
  font-size: 24px;
  border: none;
  border-radius: 5px;
  outline: none;
  padding: 10px;
  background-color: rgb(37, 53, 111);
  color: white;
}
.inputHeader {
  margin: 10px;
}
@media screen and (max-width: 600px) {
  .Input {
    font-size: 16px !important;
  }
}
