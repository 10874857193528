.nonUsTitle {
  text-align: center;
  margin-top: 15px;
  color: white;
}

.nonUsHeading {
  margin-top: 15px;
  color: white;
  text-align: center;
  font-weight: 150px;
  font-size: 20px;
}

.entitySplashText {
  color: #f3f3f3;
  font-weight: 400;
  margin: 40px;
  font-size: large;
}

.listItems {
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
}

.bullet {
  background-color: rgb(216, 25, 255);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 7px 0;
  margin-right: 5px;
}

.bulletText {
  padding: 0;
  margin: 0;
  text-align: left;
  color: #f3f3f3;
  font-size: large;
}

.getStarted {
  font-size: large;
  margin-top: 30px;
}

@media screen and (max-width: 600px) {
  .nonUsHeading {
    font-size: 16px !important;
  }
}