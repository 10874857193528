@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto','Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: rgb(216, 25, 255) !important;
    height: 4px;
}

.ant-steps-icon-dot::after {
    color: rgb(68, 61, 239) !important;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
    color: white !important;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: white !important;
    /* font-size: 20px; */
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: white !important;
    font-size: 17px;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
    color: white !important;
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: white !important;
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
    color: white !important;
}

.ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: white !important;
}

.stepperContainer {
    color: white;
    width: 65%;
    padding-top: 40px;
    margin: auto !important;
}

.stepContainer {
    margin-bottom: 40px;
}

@media screen and (max-width: 800px) {
    .stepperContainer {
        width: 90% !important;
    }
}

@media screen and (max-width: 700px) {
    .stepperContainer {
        width: 90% !important;
    }
    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
        color: white !important;
        font-size: 15px;
    }
}

@media screen and (max-width: 500px) {
    .stepperContainer {
        width: 100% !important;
    }
}

@media screen and (max-width: 576px) {
    .stepContainer {
        display: none !important;
      
    }.stepperContainer{
      display: flex;
      align-items: center;
      justify-content: center;
    }
}

.wrapper {
    color: white;
    width: 60%;
    padding: 30px;
    margin: auto;
}
.button-cont-text {
  padding-top: 60px;
}

.optionList{
    color:grey ;
    background-color:lightgray ;
    border:none
}
.mainContainer {
  padding: 10px;
  margin-top: 15px;
  color: white;
  font-size: 20px;
  background-color: #06205b;
}
.inputContainer {
  display: flex;
  align-items: center;
}
.input {
  margin: 10px;
  text-align: center;
}
.labelText {
  font-size: 16px;
  text-align: start;
}

.title {
  text-align: center;
  color: white;
  font-weight: 100;
}
.body {
  height: 400%;
}
.buttonTitle {
  color: white !important;
}
.radioButtonContainer{
  margin-top: 20px;
}
.radioButtonTitle{
  text-align: center;
}
@media screen and (max-width: 600px) {
  .buttonTitle {
    font-size: 18px !important;
  }
}

.inputField {
  width: 100%;
  font-size: 20px;
  padding: 10px;
  outline: none;
  border: none;
  border-bottom: 2px solid rgb(216, 25, 255);
  background-color: transparent;
  color: white;
  text-align: start;
}

input:-webkit-autofill,
select:-webkit-autofill {
  -webkit-text-fill-color: white;
  -webkit-transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
  transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
}

::-webkit-calendar-picker-indicator {
  background-color: rgb(216, 25, 255);
  border-radius: 5px;
  font-size: 16px;
  position: relative;
}

.nonUsTitle {
  text-align: center;
  margin-top: 15px;
  color: white;
}

.nonUsHeading {
  margin-top: 15px;
  color: white;
  text-align: center;
  font-weight: 150px;
  font-size: 20px;
}

.entitySplashText {
  color: #f3f3f3;
  font-weight: 400;
  margin: 40px;
  font-size: large;
}

.listItems {
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
}

.bullet {
  background-color: rgb(216, 25, 255);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 7px 0;
  margin-right: 5px;
}

.bulletText {
  padding: 0;
  margin: 0;
  text-align: left;
  color: #f3f3f3;
  font-size: large;
}

.getStarted {
  font-size: large;
  margin-top: 30px;
}

@media screen and (max-width: 600px) {
  .nonUsHeading {
    font-size: 16px !important;
  }
}
.mainTitle {
  color: white;
  font-weight: bold;
  font-size: 20px;
}

.mainheadingContainer {
  color: white;
  text-align: center;
  margin: 10px;
}

.mainTitleContainer {
  text-align: center;
}

.inputContainerr {
  margin-top: 20px;
}

.datePickerField {
  width: 100%;
  font-size: 20px;
  padding: 10px;
  outline: none;
  border: none;
  background-color: transparent;
  color: white;
  text-align: start;
  height: 100%;
}

.ant-picker {
  width: 100%;
  border: none !important;
  font-size: 20px !important;
  color: #fff !important;
  background-color: transparent !important;
  border-bottom: 2px solid rgb(216, 25, 255) !important;
  padding: 10px !important;
}

.ant-picker-focused {
  border: none !important;
  border-bottom: 2px solid rgb(216, 25, 255) !important;
  border-color: rgb(216, 25, 255) !important;
  border-right: none !important;
  box-shadow: none !important;
}

.ant-picker-input>input {
  font-size: 20px !important;
  color: #fff !important;
}

.ant-picker-input>input::-webkit-input-placeholder {
  color: #757575 !important;
}

.ant-picker-input>input:-ms-input-placeholder {
  color: #757575 !important;
}

.ant-picker-input>input::placeholder {
  color: #757575 !important;
}

.ant-picker-suffix {
  background-color: transparent !important;
  color: #fff !important;
}

.ant-picker-clear {
  background-color: rgb(3, 14, 50) !important;
  color: #fff !important;
}



.datePicker {
  color: white;
  border: none;
  border-bottom: 2px solid rgb(216, 25, 255);
  padding: 10px;
  background-color: transparent;
  text-align: start;
  font-size: 20px;
  width: 100%
}



.datePickerContainer {
  position: "relative";
  min-height: 30px;
}

.hiddenfield {
  position: absolute;
  z-index: 1;
  width: 100%;
  max-height: 30px;
  min-height: 30px;
}

.hiddenfieldInput {
  width: 100%;
  font-size: 20px;
  padding: 10px;
  outline: none;
  border: none;
  border-bottom: 2px solid rgb(216, 25, 255);
  background-color: transparent;
  color: white;
  text-align: start;
  height: 50px;
}

.datePickerContent {
  z-index: 2;
  color: "white";
  background-color: #030e32;
  height: 100%;
  width: 160px;
}

.datePickerTopField {
  display: flex;
  justify-content: space-between;
  height: 30px;
}
.privacyContainer {
  padding: 12px 8px;
  display: flex;
  background-color: #0E2061;
  justify-content: space-around;
  align-items: center;
  border-radius: 3px;
  margin: 15px 0px;

}

.conatinerText {
  margin: 0;
  padding: 0;
  font-size: medium;
}

.privacyContainer:hover {
  opacity: 0.6;
  cursor: pointer;
}

.modal-content {
  background-color: rgb(3, 14, 50) !important;
  color: #f3f3f3;
}

.privacyText {
  color: rgb(216, 25, 255);
}

.close {
  background-color: rgb(3, 14, 50);
  border: none;
  font-size: x-large;
}
/* .header {
    padding: 30px;
    height: 100%;
    background-color: rgb(3, 14, 50) !important;
  }
   */
.successScreenContainer {
  color: white;
  text-align: center;
  margin: 20px;
}
.succesTitle {
  font-size: 54px;
  margin: 20px;
  font-weight: 100 !important;
  color: #d818ff;
}

.logoContainer {
  margin: 20px 20px 40px 20px;
  text-align: center;
}

.title {
  font-size: 26px;
  font-weight: 200;
  text-align: center;
  margin: 20px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  border: none;
  border-top: 2px solid #142e6e;
  padding: 10px;
}

.Icon {
  width: 30px;
  height: 30px;
}

.titleMain {
  font-size: 20px;
  font-weight: 200;
  margin: 10px;
  padding: 10px;
}

.topContainer {
  color: white;
  text-align: center;
  /* margin: 20px */
}

.announcementTitle {
  color: #aa17d2;
  font-weight: bold;
}

.announcementTitleContainer {
  margin-top: 50px;
}

.titleText {
  color: white;
}

.heading {
  color: white;
  margin: 10px;
}

.documentTitleContainer {
  color: white;
  text-align: center;
  margin: 20px;
}

.imageName {
  color: rgb(216, 25, 255);
}

.captureButton {
  margin: 10px;
  border: none;
  background-color: transparent;
}

.captureLogo {
  width: 60px;
}

.backsideButton {
  width: 100%;
  height: 50px;
  background-color: rgb(68, 61, 239);
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  margin-top: 30px;
}

.backsideButtonDisable {
  width: 100%;
  height: 50px;
  background-color: #051d56;
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  margin-top: 30px;
}

.documentContainer {
  padding: 30px;
  height: 100%;
  background-color: rgb(3, 14, 50);
}

.documentImage {
  width: 100%;
  height: 200px;
  margin-top: 15px;
}

.button-cont-text {
  padding-top: 30px;
}

.headerWithBack {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 500px) {
  .heading {
    font-size: 20px;
  }

  .inputFile {
    font-size: 18px !important;
  }

  .imageName {
    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {
  .heading {
    font-size: 16px;
  }

  .inputFile {
    font-size: 15px !important;
    text-align: center;
  }
}
.dropzone {
  margin-top: 10px;
}
.dropzoneContainer {
  width: 100%;
  border: 2px dashed #79a8e6;
  height: 100%;
  cursor: pointer;
}
.dropzoneContainerContent {
  color: white;
  font-weight: 400;
  margin-top: 14px;
}
.span {
  color: #559df9;
  margin-left: 3px;
  font-weight: bold;
}
.heading {
  padding: 10px;
}
@media screen and (max-width: 450px) {
  .dropzoneContainerContent {
    font-size: small;
  }
}

.logoContainer {
  margin: 20px 20px 40px 20px;
  text-align: center;
}

.title {
  font-size: 26px;
  font-weight: 200;
  text-align: center;
  margin: 20px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  border: none;
  border-top: 2px solid #142e6e;
  padding: 10px;
}

.Icon {
  width: 30px;
  height: 30px;
}

.titleMain {
  font-size: 20px;
  font-weight: 200;
  margin: 10px;
  padding: 10px;
}

.topContainer {
  color: white;
  text-align: center;
  /* margin: 20px */
}

.announcementTitle {
  color: #aa17d2;
  text-align: start;
}

.announcementTitleContainer {
  margin-top: 50px;
}

.titleText {
  color: white;
}

.heading {
  color: white;
  margin: 10px;
}

.documentTitleContainer {
  color: white;
  text-align: center;
  margin: 20px;
}

.imageName {
  color: rgb(216, 25, 255);
}

.captureButton {
  margin: 10px;
  border: none;
  background-color: transparent;
}

.captureLogo {
  width: 60px;
}

.backsideButton {
  width: 100%;
  height: 50px;
  background-color: rgb(68, 61, 239);
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  margin-top: 30px;
}

.backsideButtonDisable {
  width: 100%;
  height: 50px;
  background-color: #051d56;
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  margin-top: 30px;
}

.documentContainer {
  padding: 30px;
  height: 100%;
  background-color: rgb(3, 14, 50);
}

.documentImage {
  width: 100%;
  height: 200px;
  margin-top: 15px;
}

.button-cont-text {
  padding-top: 30px;
}

@media screen and (max-width: 500px) {
  .heading {
    font-size: 20px;
  }

  .inputFile {
    font-size: 18px !important;
  }

  .imageName {
    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {
  .heading {
    font-size: 16px;
  }

  .inputFile {
    font-size: 15px !important;
    text-align: center;
  }
}
.container1 {
  width: 100%;
  margin: auto;
}

.titleMainContainer {
  color: white;
  text-align: center;
  margin: 20px;
}

.termLink {
  color: rgb(216, 25, 255);
  font-weight: 400;
}

.termLink:hover {
  color: rgb(144, 21, 168) !important;
}

.TermsButton {
  width: 100%;
  height: 50px;
  background-color: rgb(68, 61, 239);
  border: none;
  color: white;
  font-size: 15px;
  border-radius: 5px;
  font-weight: bold;
}

.deactiveButton {
  width: 100%;
  height: 50px;
  background-color: #051d56;
  border: none;
  color: white;
  font-size: 15px;
  border-radius: 5px;
  font-weight: bold;
}

.headerContainer {
  color: white;
  text-align: center;
  margin: 10px;
}

.termsContainer {
  margin-top: 15px;
  color: white;
  font-size: 20px;
  background-color: #06205b;
  line-height: 22px;
}

.inputsMainContainer {
  margin-top: 20px;
}

.successLogo {
  color: white;
  text-align: center;
  margin: 20px;
}

.inputContainer {
  padding: 10px 0px;
}

.titleContainerSuccess {
  color: white;
  text-align: center;
  margin: 20px;
}

.inputFields {
  font-size: 30px;
  background-color: rgb(37, 53, 111);
  border: none;
  outline: none;
  text-align: center;
  color: white !important;
  border-radius: 5px 5px 0px 0px;
  margin: 10px;
  width: 50px !important;
}

.continueBtn {
  padding-top: 20px;
}

.optionList {
  color: white;
  background-color: #33334d;
  border: none;
  opacity: 1 !important;
}

.agreeTermsContainer {
  margin: 15px 0px
}
.loadingContainer {
  text-align: center;
width: 100%;
  /* margin: 20px; */
}
.loadingTitle {
  font-size: 44px;
  font-weight: 400px ;
  color: white;

}
.loadingImage{
  width: 400px;
  height: 400px;
  margin-top: 80px;
}
@media screen and (max-width: 500px) {
.loadingImage{
  width: 300px !important;
}
}
.homeScreenSuccess {
  color: white;
  text-align: center;
  margin-top: 140px;
}
.succesTitle {
  font-size: 54px;
  margin: 20px;
  font-weight: 100 !important;
  color: #d818ff;
}
.setUpAccountBtn {
  color: #d819ff;
  background-color: transparent;
  border: none;
}
.carl-logo {
  width: 200px;
  height: 80px;
  margin-right: 20px;
}
.OverViewLogo {
  width: 400px;
  margin-top: 40px;
  /* height: 140px; */
}
.HomePageContainer {
  padding: 30px;
  height: 100%;
  background-color: rgb(3, 14, 50);
}
.carlLogoContainer {
  margin-top: 80px;
  text-align: center;
}

.porfolioHeading {
  color: rgb(102, 112, 146);
  font-weight: bold;
  font-size: 26px;
}
.extraText {
  color: white;
}
.chartMainContainer {
  padding: 5px;
  margin: 5px;
  height: 90%;
}
@media screen and (max-width: 550px) {
  .OverViewLogo {
    width: 300px;
    height: 120px;
  }
  .porfolioHeading {
    font-size: 15px;
  }
  .extraText {
    font-size: 12px;
  }
  .setUpAccountBtn span {
    font-size: 14px;
  }
}
@media screen and (max-width: 450px) {
  .OverViewLogo {
    width: 100%;
    height: 120px;
  }
}

.header {
  padding: 30px;
  height: 100%;
  background-color: rgb(3, 14, 50);
  text-align: center;
}

.Maincontainer {
  width: 40% !important;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.containerRow {
  background-color: rgb(3, 14, 50);
  padding: 30px;
}

.login-H2 {
  color: white;
  padding: 10px;
}

.button {
  padding: 30px;
  margin: 10px;
  width: 100%;
}

.buttonText {
  width: 150px;
  height: 40px;
  background-color: rgb(6, 34, 97);
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  margin: 10px;
  border: 1px solid transparent;
}

.activeButtonText {
  width: 150px;
  height: 40px;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  background-color: rgb(68, 61, 239);
  border: 1px solid rgb(216, 25, 255);
  margin: 10px;
}

.btnContainer {
  display: flex;
  justify-content: center;
}

.continueBtn {
  padding-top: 20px;
}

.button-cont-text {
  padding-top: 60px;
}

.footer {
  color: white;
  text-align: center;
  margin-top: 10px;
}

.footerSpan {
  color: rgb(216, 25, 255);
  font-weight: bold;
  margin-left: 4px;
}

.Icon {
  margin-right: 7px;
}

.deactive-button {
  background-color: #051d56;
  width: 100%;
  height: 45px;
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
}

.mainHeader {
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .login-H2 {
    font-size: 24px;
  }
}

@media screen and (max-width: 900px) {
  .login-H2 {
    font-size: 20px;
  }

  .buttonText {
    width: 130px;
    height: 35px;
  }

  .activeButtonText {
    width: 130px;
    height: 35px;
  }
}

@media screen and (max-width: 400px) {
  .login-H2 {
    font-size: 17px;
  }
}
.Input {
  height: 100%;
  width: 100%;
  font-size: 24px;
  border: none;
  border-radius: 5px;
  outline: none;
  padding: 10px;
  background-color: rgb(37, 53, 111);
  color: white;
}
.inputHeader {
  margin: 10px;
}
@media screen and (max-width: 600px) {
  .Input {
    font-size: 16px !important;
  }
}

.container {
  padding: 0px;
  width: 50% !important;
  margin-top: 70px;
}
.container1 {
  padding: 0px;
  width: 40% !important;
  margin-top: 70px !important ;
}
.verificationHeader{
  text-align: center 
}
.mainTitle {
  color: white;
  padding: 10px;
  font-weight: bold;
  font-size: 23px;
}
.button {
  padding: 20px;
  margin: 10px;
}
.buttonContinue {
  width: 100%;
  height: 45px;
  background-color: rgb(68, 61, 239);
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
}
.disableButton {
  width: 100%;
  height: 45px;
  background-color: #051d56;
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
}
i {
  margin-right: 5px;
}
.button-cont-text {
  padding-top: 10px;
}
.mainHeader {
  text-align: center;
}
.title {
  color: white;
  text-align: center;
  font-size: 26px !important;
  font-weight: 300 !important;
}
.titleNumber {
  text-align: start;
  color: lightgray;
}
@media screen and (max-width: 1800px) {
  .container {
    width: 65% !important;
  }
}
@media screen and (max-width: 1600px) {
  .container {
    width: 70% !important;
  }
}
@media screen and (max-width: 1400px) {
  .container {
    width: 70% !important;
  }
  .title {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 1200px) {
  .container {
    width: 80% !important;
  }
  .title {
    font-size: 24px !important;
  }
  .container1 {
    width: 45% !important;
  }
}
@media screen and (max-width: 1000px) {
  .container {
    width: 90% !important;
  }
  .buttonContinue {
    height: 45px;
    padding: 5px;
  }
  .disableButton {
    height: 45px;
    padding: 5px;
  }
  .title {
    font-size: 22px !important;
  }
  .container1 {
    width: 55% !important;
  }
}
@media screen and (max-width: 800px) {
  .container {
    width: 90% !important;
  }
  .title {
    font-size: 22px !important;
  }
  .container1 {
    width: 65% !important;
  }
}
@media screen and (max-width: 600px) {
  .container {
    width: 100% !important;
  }
  .title {
    font-size: 20px !important;
  }
  .container1 {
    width: 75% !important;
  }
}
@media screen and (max-width: 400px) {
  .container {
    width: 100% !important;
  }
  .container1 {
    width: 85% !important;
  }
}
@media screen and (max-width: 300px) {
  .container {
    width: 100% !important;
  }
  .container1 {
    width: 95% !important;
  }
}
@media screen and (max-width: 576px) {
  .container {
    margin-top: 40px;
  }
}

.titleCode {
  color: white;
  text-align: center;
  font-weight: bold;
}
.activeButton {
  width: 100%;
  height: 50px;
  background-color: rgb(68, 61, 239);
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
}
.deactive-button {
  background-color: #051d56;
  width: 100%;
  height: 45px;
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
}
.inputFields {
  font-size: 30px;
  background-color: rgb(37, 53, 111);
  border: none;
  outline: none;
  text-align: center;
  color: white !important;
  border-radius: 5px 5px 0px 0px;
  margin: 20px;
  width: 50px !important;
}
.mainHeader {
  text-align: center;
}
.otpMessageContainer{
  margin-top: 20px
}
.otpContainer {
  display: flex;
  justify-content: space-around;
}
.otpQuestionText {
  background-color: transparent;
  border: none;
  outline: none;
  font-weight: bold !important;
}
.otpQuestionTextenable {
  color: rgb(216, 25, 255);
  border: none;
  outline: none;
  font-weight: bold;
  background-color: transparent;
}
.errorText {
  color: #ff4d4d;
}

@media screen and (max-width: 800px) {
  .activeButton {
    height: 45px !important;
  }
  .deactive-button {
    height: 45px !important;
  }
  .titleCode {
    font-size: 15px !important;
  }
  .inputFields {
    width: 40px !important;
    margin: 10px;
    font-size: 24px;
  }
}
@media screen and (max-width: 500px) {
  .titleCode {
    font-size: 13px !important;
  }
}

.App {
  text-align: center;
}

body {
  background: linear-gradient(rgb(6, 34, 97), rgb(3, 14, 50)) fixed;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
